<template>
    <div class="c-flipcard" :class="{ 'c-flipcard--active' : current === 1 }">
        <div class="c-flipcard__inner" v-touch:swipe="swipeHandler()" :current.sync="current">
            <div class="c-flipcard__img-wrap">
                <div class="c-flipcard__img" :style="{ backgroundImage: `url('${ image }')` }"></div>
                <div class="c-flipcard__author" v-if="data.quote">
                    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94 59" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M21.992 56.636l1.394-12.558.154-1.388h-1.397c-11.55 0-20.893-9.286-20.893-20.726 0-11.427 9.344-20.714 20.881-20.714 9.958 0 18.286 6.915 20.383 16.154 3.646 16.122-6.883 30.679-20.522 39.232zm49.501 0l1.394-12.558.154-1.388h-1.396c-11.55 0-20.882-9.286-20.882-20.714v-.001c-.01-11.439 9.332-20.725 20.87-20.725 9.958 0 18.286 6.915 20.383 16.154 3.646 16.122-6.883 30.679-20.523 39.232z"/></svg>
                    <p class="c-flipcard__author-name">{{ data.quote[0].post_title }}</p>
                </div>
            </div>

            <div class="c-flipcard__content">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94 59" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M21.992 56.636l1.394-12.558.154-1.388h-1.397c-11.55 0-20.893-9.286-20.893-20.726 0-11.427 9.344-20.714 20.881-20.714 9.958 0 18.286 6.915 20.383 16.154 3.646 16.122-6.883 30.679-20.522 39.232zm49.501 0l1.394-12.558.154-1.388h-1.396c-11.55 0-20.882-9.286-20.882-20.714v-.001c-.01-11.439 9.332-20.725 20.87-20.725 9.958 0 18.286 6.915 20.383 16.154 3.646 16.122-6.883 30.679-20.523 39.232z"/></svg>
                <p>{{ data.text }}</p>
            </div>
        </div>

        <div class="c-flipcard__controls">
            <button class="c-btn c-btn--gallery-dot" :class="btnClass(i)" v-for="i in slides" :key="i" @click="current = i">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="9.407" cy="9.657" r="8.309"/></svg>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Flipcard',
        props: {
            data: Object
        },

        data() {
            return {
                current: 0,
                slides: [0, 1]
            }
        },

        methods: {
            btnClass(index) {
                return {
                    'c-btn--gallery-dot-active': this.current === index
                }
            },

            swipeHandler() {
                return (dir) => {
                    if (dir === 'left') this.current = 1
                    if (dir === 'right') this.current = 0
                }
            },
        },

        computed: {
            image() {
                if (this.data.type === 'free') return this.data.image.sizes.full.src[0]
                return this.data.quote[0].featured_image.sizes.full.src[0]
            }
        }
    }
</script>

<style lang="scss" src="@/styles/components/_flipcard.scss"></style>